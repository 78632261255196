<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    aria-labelledby="SignalPercent"
    role="presentation"
  >
    <title
      id="SignalPercent"
      lang="en"
    >SignalPercent icon</title>
    <g :fill="iconColor">
      <path v-show="percent > 27 && percent < 98" d="M21,4H16V20H21V4M14,9H9V20H14V9M7,14H2V20H7V14Z"/>
      <path v-show="percent > 7 && percent <= 27" d="M19.5,5.5V18.5H17.5V5.5H19.5M21,4H16V20H21V4M14,9H9V20H14V9M7,14H2V20H7V14Z"/>
      <path v-show="percent > 0 && percent <= 7" d="M19.5,5.5V18.5H17.5V5.5H19.5M12.5,10.5V18.5H10.5V10.5H12.5M21,4H16V20H21V4M14,9H9V20H14V9M7,14H2V20H7V14Z"/>
      <path v-show="percent === 0" d="M19.5,5.5V18.5H17.5V5.5H19.5M12.5,10.5V18.5H10.5V10.5H12.5M5.5,15.5V18.5H3.5V15.5H5.5M21,4H16V20H21V4M14,9H9V20H14V9M7,14H2V20H7V14Z"/>
      <path v-show="percent === 99" d="M18,3V16.18L21,19.18V3H18M4.28,5L3,6.27L10.73,14H8V21H11V14.27L13,16.27V21H16V19.27L19.73,23L21,21.72L4.28,5M13,9V11.18L16,14.18V9H13M3,18V21H6V18H3Z"/>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
    percent: {
      type: [Number],
      default: 100
    }
  }
}
</script>
