<template>
  <b-card>
    <b-row>
      <div class="m-2">
        <b-button class="float-left" variant="primarySpore" @click="back()">Regresar</b-button>
      </div>
    </b-row>
    <div>
      <b-row class="mt-1">

        <b-col md="12" lg="3">
          <b-card class="card_statisc">
            <b-row>
              <b-col md="12" v-if="response.cfeCabinet">
                <h4>Número gabinete: </h4>
                <label class="font-medium-1 mb-1">{{response.cfeCabinet.number}}</label>
                <h4>Serie: </h4>
                <label class="font-medium-1 mb-1">{{response.serie}}</label>
                <h4>Tipo de gabinete: </h4>
                <label class="font-medium-1 mb-1" style="font-size: 20px">{{(response.cfeCabinet.type == 'P' ? 'Protecsa': response.type == 'N' ? 'Nansen': response.type == 'S' ? 'Siemens': 'Otro') }}</label>
                <h4>Agencia: </h4>
                <label class="font-medium-1 mb-1">{{response.cfeAgency.name}}</label><br>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col md="12" lg="3">
          <b-card class="card_statisc">
              <b-row>
                <b-col md="12">
                  <h4>Fecha instalación: </h4>
                  <label class="font-medium-1 mb-1">{{ response.installedAt }}</label>
                </b-col>
                <b-col md="12" class="mb-1">
                  <h4>Fecha ultima transmisión: </h4>
                  <label class="font-medium-1 mb-1">{{ response.transmitedAt }}</label>
                </b-col>
                <b-col md="12" class="mb-1">
                  <h4>Señal: </h4>
                  <icon-signal-percent class="align-middle" width="25" height="25" iconColor="#000" :percent="parseInt(response.sign)" />
                  <span class="align-middle">
                    {{ getSignalPercent(response.sign) }}
                  </span>
                </b-col>
              </b-row>
          </b-card>
        </b-col>

        <b-col cols="12" md="12">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-md-0">
                <label>Mostrar</label>
                <v-select v-model="filters.limit" :options="this.perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @input="getGabinetsSinamedLogs"/>
                <label>Registros</label>
              </b-col>
              <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-md-0">
                <label for="example-datepicker">Desde</label>&nbsp;
                <b-form-datepicker id="beginDate" placeholder="" v-model="beginDate" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-md-0">
                <label for="example-datepicker">Hasta</label>&nbsp;
                <b-form-datepicker id="finishDate" placeholder="" v-model="finishDate" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="12" md="3" class="mt-2 text-right">
                <b-button variant="primarySpore" @click="getGabinetsSinamedLogs">
                  <feather-icon icon="SearchIcon" class="mr-50"/>
                  <span>Buscar</span>
                </b-button>
              </b-col>
            </b-row>
          </div>

          <b-table
              v-if="responseTable.length > 0"
              class="position-relative"
              hover
              responsive
              primary-key="id"
              show-emptysomething
              :items="responseTable"
              :fields="tableColumns"
            >
              <template #cell(Action)="data">
                <b-form-checkbox v-if="data.item.createdAt" v-model="data.detailsShowing" plain class="vs-checkbox-con" @change="data.toggleDetails">
                  <span class="vs-checkbox">
                    <span class="vs-checkbox--check">
                      <i class="vs-icon feather icon-check" />
                    </span>
                  </span>
                  <span class="vs-label">{{ data.detailsShowing ? 'Ocultar Registros' : 'Mostrar Registros' }}</span>
                </b-form-checkbox>
              </template>

              <template #row-details="data">
                <b-card>
                  <b-row class="mb-1">
                    <b-col md="12" class="mb-1">
                      <b-row class="mb-1 d-flex align-items-center justify-content-center">
                        <div class="col-2">
                          <statistic-card-vertical icon="RssIcon" statistic="Enviado" statistic-title="Estado" color="primary"/>
                        </div>
                        <div class="col-4">
                          <pre class="p-3">{{ JSON.stringify(data.item.send, undefined, 2) }}</pre>
                        </div>

                        <div class="col-2">
                          <statistic-card-vertical icon="CornerDownRightIcon" statistic="Recibido" statistic-title="Estado" color="primary"/>
                        </div>
                        <div class="col-4">
                          <pre class="p-3">{{ JSON.stringify(data.item.response, undefined, 2) }}</pre>
                        </div>
                      </b-row>
                    </b-col>
                  </b-row>

                  <b-button variant="outline-info" @click="data.toggleDetails">
                    Ocultar Registros
                  </b-button>
                </b-card>
              </template>

              <template #cell(meter)="data">
                <div class="text-left">
                  {{ data.item.cfeMeter.number.replace(/^(0)+/, '') }}
                </div>
              </template>

              <template #cell(command)="data">
                <div class="text-left">
                  {{ commands.filter( element => { return element.code === data.item.command})[0].label + ' # ' + commands.filter( element => { return element.code === data.item.command})[0].code }}
                </div>
              </template>

              <template #cell(response_code)="data">
                <div class="text-left">
                  {{ (data.item.response_code == 200 ? 'Conexión exitosa': 'Problemas en Conexión') }}
                </div>
              </template>

              <template #cell(createdAt)="data">
                <div class="text-center">
                  {{ format(data.item.createdAt) }}
                </div>
              </template>
            </b-table>

          <div class="mx-2 mb-2">
            <b-row>

              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <!--<span class="text-muted">Showing {{ count }} of {{ totalRows }} entries</span>-->
              </b-col>
              <!-- Pagination -->
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                <b-pagination v-model="currentPage" :total-rows="this.totalRows" :per-page="this.filters.limit" first-class last-class class="mb-0 mt-1 mt-sm-0">
                  <template #first-text>
                    <feather-icon icon="ChevronsLeftIcon" size="18"/>
                  </template>
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18"/>
                  </template>
                  <template #last-text>
                    <feather-icon icon="ChevronsRightIcon" size="18"/>
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-col>
        <br><br>
      </b-row>
    </div>

  </b-card>
</template>

<script>
import { formatDateLanguages, decimales, empty } from '@/libs/tools/helpers'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import iconSignalPercent from '@core/spore-components/icons/iconSignalPercent.vue'
// import listItem from '@core/spore-components/listItem/listItem.vue'
import {
  BButton, BRow, BCol, BCard, VBTooltip, BTable, BPagination, BFormCheckbox, BFormDatepicker
} from 'bootstrap-vue'
import request from '@/libs/request/index'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BTable,
    BPagination,
    /* listItem, */
    iconSignalPercent,
    BFormCheckbox,
    BFormDatepicker,
    StatisticCardVertical
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      uuid: this.$route.params.uuid,
      device: this.$route.params.device,
      response: [],
      commands: [
        { code: '10', label: 'Solicitud Identificador Medidor' },
        { code: '20', label: 'Solicitud Lectura General' },
        { code: '50', label: 'Solicitud de Apertura del Relevador' },
        { code: '55', label: 'Solicitud de Multi-Apertura del Relevador ' },
        { code: '60', label: 'Solicitud de Cierre del Relevador' },
        { code: '65', label: 'Solicitud de Multi-Cierre del Relevador' }
      ],
      tableColumns: [
        { thClass: 'text-left', key: 'Action', label: 'Logs', thStyle: { width: '15%' } },
        { thClass: 'text-left', key: 'meter', sortable: true, label: 'Medidor' },
        { thClass: 'text-left', key: 'command', sortable: true, label: 'Comando' },
        { thClass: 'text-left', key: 'response_code', sortable: true, label: 'Respuesta' },
        { thClass: 'text-center', key: 'createdAt', sortable: true, label: 'Fecha de Creación' }
      ],
      totalRows: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 75, 100],
      perPage: 25,
      count: 0,
      responseTable: [],
      file: '',
      avatar: '',
      beginDate: '',
      finishDate: '',
      filters: {
        limit: 25,
        offset: 0,
        byColumn: 'id',
        byOrder: 'ASC',
        beginDate: '',
        finishDate: ''
      }
    }
  },
  methods: {
    decimal (val, n) {
      return decimales(val, n)
    },
    format (date) {
      return formatDateLanguages(date, 'hour')
    },
    redirect (uuid) {
      if (!empty(uuid)) {
        this.$router.push(`/gabinets/${uuid}/detail`)
      }
    },
    getSignalPercent (value) {
      value = parseInt(value)
      if (value === 99) return 'No conocido o no detectado'
      else if (value > 30) return '-52 dBm o muy buena'
      else if (value > 1 && value <= 30) return '-110... - 54 dBm'
      else if (value === 1) return '-111 dBm'
      else if (value === 0) return '-115 dBm o menor'
      return value
    },
    async getDetailDevice () {
      const params = {
        url: `/devices/${this.device}`,
        method: 'GET'
      }
      await request(params).then(data => {
        if (data.data.code === 200) {
          this.response = data.data.data
          this.response.number = data.data.data.cfeCabinet.number.replace(/^(0)+/, '')
          this.getGabinetsSinamedLogs()
        }
      })
    },
    async getGabinetsSinamedLogs () {
      if (this.beginDate) {
        this.filters.beginDate = this.beginDate
      }
      if (this.finishDate) {
        this.filters.finishDate = this.finishDate
      }
      const params = {
        url: `/sinamed/log/cabinet/${this.uuid}`,
        method: 'GET',
        params: this.filters
      }
      await request(params).then(data => {
        if (data.data.code === 200) {
          this.responseTable = data.data.data.rows
          this.totalRows = data.data.data.count
          this.count = data.data.data.rows.length
        }
      })
    },
    back () {
      this.$router.push(`/gabinets/${this.uuid}/detail`)
    }
  },
  created () {
    this.getDetailDevice()
  },
  watch: {
    currentPage (val) {
      this.filters.offset = (val - 1) * this.filters.limit
      this.getGabinetsSinamedLogs()
    },
    byColumn (val) {
      this.filters.byColumn = val
      this.currentx = 1
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
